// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_copy-to-clipboard-button__29IxT {\n  background-color: #f5f8fa;\n}\n", "",{"version":3,"sources":["webpack://src/components/CopyToClipboardButton/style.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B","sourcesContent":[".copy-to-clipboard-button {\n  background-color: #f5f8fa;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copy-to-clipboard-button": "style_copy-to-clipboard-button__29IxT"
};
export default ___CSS_LOADER_EXPORT___;

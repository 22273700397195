// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InlineColorPicker_swatch__11WDP {\n  padding: 4px 8px;\n  background: white;\n  border: none;\n  border-bottom: 1px solid var(--light);\n  border-radius: 4px;\n  display: inline-flex;\n  align-items: center;\n  cursor: pointer;\n  width: 100%;\n  height: 100%;\n  font-size: 0.8461538462rem;\n}\n\n.InlineColorPicker_color__12j-E {\n  width: 16px;\n  height: 16px;\n  border: 1px solid #ddd;\n  margin-right: 8px;\n}\n\n.InlineColorPicker_popover__3Hchl {\n  position: absolute;\n  z-index: 2000;\n}\n\n.InlineColorPicker_cover__2KTIs {\n  position: fixed;\n  top: 0px;\n  right: 0px;\n  bottom: 0px;\n  left: 0px;\n}", "",{"version":3,"sources":["webpack://src/components/InlineColorPicker/InlineColorPicker.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,qCAAA;EACA,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,0BAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,sBAAA;EACA,iBAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;AACF;;AAEA;EACE,eAAA;EACA,QAAA;EACA,UAAA;EACA,WAAA;EACA,SAAA;AACF","sourcesContent":[".swatch {\n  padding: 4px 8px;;\n  background: white;\n  border: none;\n  border-bottom: 1px solid var(--light);\n  border-radius: 4px;\n  display: inline-flex;\n  align-items: center;\n  cursor: pointer;\n  width: 100%;\n  height: 100%;\n  font-size: 0.8461538462rem;\n}\n\n.color {\n  width: 16px;\n  height: 16px;\n  border: 1px solid #ddd;\n  margin-right: 8px;\n}\n\n.popover {\n  position: absolute;\n  z-index: 2000;\n}\n\n.cover {\n  position: fixed;\n  top: 0px;\n  right: 0px;\n  bottom: 0px;\n  left: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swatch": "InlineColorPicker_swatch__11WDP",
	"color": "InlineColorPicker_color__12j-E",
	"popover": "InlineColorPicker_popover__3Hchl",
	"cover": "InlineColorPicker_cover__2KTIs"
};
export default ___CSS_LOADER_EXPORT___;

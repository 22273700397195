// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SparqlFetch_url-input__1OTEr {\n  background: #ffffff;\n  border: 1px solid var(--gray-400);\n  border-radius: 1px;\n  height: 40px;\n  padding: 0 10px;\n}\n\n.SparqlFetch_query-input-here__1GTNa {\n  padding: 12px 0;\n}\n\n.SparqlFetch_base-iri-input-here__1MbOa {\n  padding: 0 0 12px 0;\n}\n\nsparql-editor {\n  height: 349px;\n}", "",{"version":3,"sources":["webpack://src/components/DataLoader/loaders/SparqlFetch.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,iCAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".url-input {\n  background: #ffffff;\n  border: 1px solid var(--gray-400);\n  border-radius: 1px;\n  height: 40px;\n  padding: 0 10px;\n}\n\n.query-input-here {\n  padding: 12px 0;\n}\n\n.base-iri-input-here {\n  padding: 0 0 12px 0;\n}\n\nsparql-editor {\n  height: 349px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"url-input": "SparqlFetch_url-input__1OTEr",
	"query-input-here": "SparqlFetch_query-input-here__1GTNa",
	"base-iri-input-here": "SparqlFetch_base-iri-input-here__1MbOa"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer_footer__7dIj9 {\n  background-color: var(--dark);\n  color: var(--light);\n  padding-top: 32px;\n  padding-bottom: 40px;\n  max-width: 1400px;\n}", "",{"version":3,"sources":["webpack://src/components/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,mBAAA;EACA,iBAAA;EACA,oBAAA;EACA,iBAAA;AACF","sourcesContent":[".footer {\n  background-color: var(--dark);\n  color: var(--light);\n  padding-top: 32px;\n  padding-bottom: 40px;\n  max-width: 1400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "Footer_footer__7dIj9"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_navbar__359Kr {\n  border-top: 4px solid var(--primary);\n  z-index: 6660;\n  height: var(--header-height);\n}", "",{"version":3,"sources":["webpack://src/components/Header/Header.module.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,aAAA;EACA,4BAAA;AACF","sourcesContent":[".navbar {\n  border-top: 4px solid var(--primary);\n  z-index: 6660;\n  height: var(--header-height);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": "Header_navbar__359Kr"
};
export default ___CSS_LOADER_EXPORT___;

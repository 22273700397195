// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChartSelector_thumbnail__1xQ_- {\n  background-position: center;\n  background-repeat: no-repeat;\n}\n\n.ChartSelector_currentChart__NKILM:hover {\n  background-color: white !important;\n}\n\n.ChartSelector_disabled__MRXKu {\n  pointer-events: none;\n  filter: grayscale(1);\n}", "",{"version":3,"sources":["webpack://src/components/ChartSelector/ChartSelector.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,4BAAA;AACF;;AAEA;EACE,kCAAA;AACF;;AAEA;EACE,oBAAA;EACA,oBAAA;AACF","sourcesContent":[".thumbnail {\n  background-position: center;\n  background-repeat: no-repeat;\n}\n\n.currentChart:hover {\n  background-color: white !important;\n}\n\n.disabled {\n  pointer-events: none;\n  filter: grayscale(1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thumbnail": "ChartSelector_thumbnail__1xQ_-",
	"currentChart": "ChartSelector_currentChart__NKILM",
	"disabled": "ChartSelector_disabled__MRXKu"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UrlFetch_url-input__3vKK2 {\n  background: #ffffff;\n  border: 1px solid var(--gray-400);\n  border-radius: 1px;\n  height: 40px;\n  padding: 0 10px;\n}", "",{"version":3,"sources":["webpack://src/components/DataLoader/loaders/UrlFetch.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,iCAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;AACF","sourcesContent":[".url-input {\n  background: #ffffff;\n  border: 1px solid var(--gray-400);\n  border-radius: 1px;\n  height: 40px;\n  padding: 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"url-input": "UrlFetch_url-input__3vKK2"
};
export default ___CSS_LOADER_EXPORT___;

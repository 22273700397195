// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Section_section__2mYr1 {\n  border-bottom: 1px solid var(--light);\n  padding-top: 32px;\n  padding-bottom: 40px;\n  max-width: 1400px;\n}", "",{"version":3,"sources":["webpack://src/components/Section/Section.module.scss"],"names":[],"mappings":"AAAA;EACE,qCAAA;EACA,iBAAA;EACA,oBAAA;EACA,iBAAA;AACF","sourcesContent":[".section {\n  border-bottom: 1px solid var(--light);\n  padding-top: 32px;\n  padding-bottom: 40px;\n  max-width: 1400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "Section_section__2mYr1"
};
export default ___CSS_LOADER_EXPORT___;

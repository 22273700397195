// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UploadFile_dropzone__1h-vB {\n  height: 432px;\n  border: 1px dashed var(--gray-300);\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n\n.UploadFile_dropzone__1h-vB.UploadFile_reject__2Kpgt {\n  border: 2px dashed var(--danger);\n}\n\n.UploadFile_dropzone__1h-vB.UploadFile_accept__3mK1X {\n  border: 2px dashed var(--success);\n}\n\n.UploadFile_browse-button__2AUZ1 {\n  font-size: 13px;\n  line-height: 16px;\n  text-align: center;\n  color: #ffffff;\n  height: 28px;\n  width: 80px;\n  margin: 0 10px;\n}", "",{"version":3,"sources":["webpack://src/components/DataLoader/loaders/UploadFile.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,gCAAA;AACF;;AAEA;EACE,iCAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;AACF","sourcesContent":[".dropzone {\n  height: 432px;\n  border: 1px dashed var(--gray-300);\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n\n.dropzone.reject {\n  border: 2px dashed var(--danger);\n}\n\n.dropzone.accept {\n  border: 2px dashed var(--success);\n}\n\n.browse-button {\n  font-size: 13px;\n  line-height: 16px;\n  text-align: center;\n  color: #ffffff;\n  height: 28px;\n  width: 80px;\n  margin: 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropzone": "UploadFile_dropzone__1h-vB",
	"reject": "UploadFile_reject__2Kpgt",
	"accept": "UploadFile_accept__3mK1X",
	"browse-button": "UploadFile_browse-button__2AUZ1"
};
export default ___CSS_LOADER_EXPORT___;
